import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsColorOverrides } from '@mui/material/Button';

interface ErrorDialogProps {
  open: boolean;
  title: string;
  message1: string;
  message2?: string;
  button1Text: string;
  button1Action: () => void;
  button1Color: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>;
  button2Text?: string;
  button2Action?: () => void;
  button2Color?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  open,
  title,
  message1,
  message2,
  button1Text,
  button1Action,
  button1Color,
  button2Action,
  button2Text,
  button2Color,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          padding: '24px',
          boxSizing: 'border-box',
          height: 'auto',
        },
      }}
    >
      <DialogTitle>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '38.4px',
            letterSpacing: '0.15px',
            textAlign: 'left',
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '27px',
            letterSpacing: '0.15px',
            textAlign: 'left',
            marginBottom: '16px',
          }}
        >
          {message1}
        </Typography>
        {message2 && (
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '27px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '16px',
            }}
          >
            {message2}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button onClick={button1Action} color={button1Color}>
          {button1Text}
        </Button>
        {button2Text && button2Action && (
          <Button onClick={button2Action} color={button2Color}>
            {button2Text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
