import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

const TabsPanel: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: '#FFFFFF' } }}>
      <Tab icon={<MenuIcon sx={{ color: '#FFFFFF' }} />} label='MENU' iconPosition='start' sx={{ color: '#FFFFFF' }} />
      <Tab icon={<LeaderboardIcon sx={{ color: '#FFFFFF' }} />} label='DASHBOARD' iconPosition='start' sx={{ color: '#FFFFFF' }} />
    </Tabs>
  );
};

export default TabsPanel;
