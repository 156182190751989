import CryptoJS from 'crypto-js';

// Função para criptografar uma string
export const criptografar = (data: string): string => {
  const encrypted = CryptoJS.AES.encrypt(data, process.env.REACT_APP_SECRET_CRIPTO ?? '').toString();
  return encrypted;
};

// Função para descriptografar uma string
export const decriptografar = (data: string): string => {
  const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_CRIPTO ?? '');
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};
