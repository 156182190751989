import React, { useEffect, useState } from 'react';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { TopNavBar } from 'components/TopNavBar';
import { Header } from 'components/Home';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PersonalDataDisplay from 'components/User/PersonalDataDisplay';
import LoginDataDisplay from 'components/User/LoginDataDisplay';
import RegistrationDataDisplay from 'components/User/RegistrationDataDisplay';
import { EmptyContent, ErrorDialog } from 'components/Shared';
import { getUserDetails } from 'api/user/user.api';
import { formatCPF } from 'services/utils/formatCPF';
import { ROUTES } from 'routes/constants';

interface UserDetailsData {
  personalData: {
    name: string;
    cpf: string;
    email: string;
    phone: string;
    position: string;
    occupation: string;
    municipality: string;
  };
  loginData: {
    group: string;
    profile: string;
    permission: string;
  };
  registrationData: {
    activeRegistration: string;
    blockRegistration: string;
    termStartDate: string;
    termEndDate: string;
    termEndDateIndefinite: string;
  };
}

const UserView: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const [isEmptyContent, setIsEmptyContent] = useState(true);
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetailsData | null>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (!uuid) {
          setIsEmptyContent(true);
          return;
        }
        const userDetailsData = await getUserDetails(uuid);
        if (userDetailsData) {
          const separatedData: UserDetailsData = {
            personalData: {
              name: userDetailsData.name,
              cpf: formatCPF(userDetailsData.cpf),
              email: userDetailsData.email,
              phone: userDetailsData.phone,
              position: userDetailsData.position,
              occupation: userDetailsData.occupation,
              municipality: userDetailsData.municipality,
            },
            loginData: {
              group: userDetailsData.group,
              profile: userDetailsData.profile,
              permission: userDetailsData.permission,
            },
            registrationData: {
              activeRegistration: userDetailsData.activeRegistration === true ? 'Não' : 'Sim',
              blockRegistration: userDetailsData.blockRegistration === true ? 'Sim' : 'Não',
              termStartDate: userDetailsData.termStartDate,
              termEndDate: userDetailsData.termEndDate,
              termEndDateIndefinite: userDetailsData.termEndDateIndefinite,
            },
          };

          setUserDetails(separatedData);
          setIsEmptyContent(false);
        } else {
          setIsEmptyContent(true);
        }
      } catch (error) {
        setIsEmptyContent(true);
        setInvalidRequest(true);
      }
    };

    fetchUserDetails();
  }, [uuid]);

  const handleRefresh = () => {
    window.location.reload();
    setInvalidRequest(false);
  };

  const handleBack = () => {
    navigate(ROUTES.MANAGE_USER);
  };

  return (
    <MenuProvider>
      <TopNavBar />
      <Box
        sx={{
          backgroundColor: '#0048B6',
          marginTop: '10px',
          height: '65px',
          alignContent: 'center',
        }}
      >
        <Header title='Usuários' showBackButton={true} />
        {isEmptyContent ? (
          <EmptyContent />
        ) : (
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
            }}
          >
            <Box
              sx={{
                paddingTop: '50px',
              }}
            >
              <Typography variant='h5'>Visualizar usuário</Typography>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '22.88px',
                  letterSpacing: '0.17px',
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                Visualize todos os dados do usuário no sistema.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>
              {userDetails && userDetails.personalData && <PersonalDataDisplay values={userDetails.personalData} isView={true} />}
              {userDetails && userDetails.loginData && <LoginDataDisplay values={userDetails.loginData} isView={true} />}
              {userDetails && userDetails.registrationData && <RegistrationDataDisplay values={userDetails.registrationData} isView={true} />}
            </Box>

            <Box sx={{ marginTop: '30px' }}>
              <Button
                variant='text'
                onClick={handleBack}
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                  textAlign: 'left',
                  color: '#0048B6',
                  marginRight: '20px',
                }}
              >
                VOLTAR
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={() => setInvalidRequest(false)}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </MenuProvider>
  );
};

export default UserView;
