import React from 'react';
import TopLinksBar from './TopLinksBar';
import { Avatar, Badge } from '@mui/material';
import logoMtGovBrIcon from '../../assets/icons/logo-mtgovbr-icon.svg';
import NotificationsIcon from '@mui/icons-material/Notifications';
import exampleAvatar from '../../assets/images/example-avatar.png';
import { CustomSecondaryAppBar, LogoContainer, RightContainer } from './TopNavBar.styles';

const TopNavBar: React.FC = () => {
  return (
    <>
      <TopLinksBar />
      <CustomSecondaryAppBar>
        <LogoContainer>
          <img src={logoMtGovBrIcon} alt='Logo' style={{ height: '40px' }} />
        </LogoContainer>
        <RightContainer>
          <Badge badgeContent={1} color='primary'>
            <NotificationsIcon color='action' fontSize='large' />
          </Badge>
          <Avatar alt='Example Avatar' src={exampleAvatar} />
        </RightContainer>
      </CustomSecondaryAppBar>
    </>
  );
};

export default TopNavBar;
