/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Box, Avatar, Alert } from '@mui/material';
import IconMtLoginBlue from '../../assets/icons/logo-mtlogin-blue-icon.svg';
import IconMtLoginGray from '../../assets/icons/logo-mtlogin-gray-icon.svg';
import SeiafMtLogoImage from '../../assets/images/seiaf-mt-logo-image.png';
import { getURLMTLogin } from 'services/auth/config';
import { getUserLocalStorage } from 'services/auth/authService';
import { formatCpfHiddenNumberMask } from 'utils/formatCPF';

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: '#00000061',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const LoginPage: React.FC = () => {
  const { name, cpf } = getUserLocalStorage() ?? {};

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async () => {
    window.location.href = getURLMTLogin();
  };

  useEffect(() => {
    console.log('useEffect -> Login');
    if (name && cpf) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '40px',
        }}
      >
        <Box sx={{ maxWidth: '1080px', mb: 2 }}>
          <Alert
            severity='info'
            action={
              <Button sx={{ width: '90px', color: 'black' }} size='small'>
                SAIBA MAIS
              </Button>
            }
          >
            <Typography variant='body2' align='left' sx={{ fontSize: '14px', lineHeight: '20.02px', letterSpacing: '0.17px' }}>
              A Ferramenta Eletrônica do SEIAF MT (e-SEIAF) tem a finalidade de coletar dados primários sobre a agricultura familiar dos municípios de Mato
              Grosso para subsidiar a formulação, monitoramento e reorientação de ações mais efetivas em prol do fortalecimento do segmento.
            </Typography>
          </Alert>
        </Box>
      </Box>
      <Box>
        <img src={SeiafMtLogoImage} alt='Sistema Estadual Integrado da Agricultura Familiar' />
      </Box>
      <Box
        component='footer'
        sx={{
          backgroundColor: '#008D7A',
          height: '261px',
          mt: 'auto',
          pt: '60px',
          width: '100%',
        }}
      >
        <Button
          sx={{
            width: '560px',
            height: '62px',
            padding: 0,
            backgroundColor: '#FFFFFF',
            color: '#000000DE',
            '&:hover': {
              backgroundColor: '#FFFFFF',
            },
          }}
          variant='contained'
          onClick={handleLogin}
        >
          {!isLoggedIn ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  color: 'text.primary',
                  fontSize: '15px',
                  fontWeight: 500,
                  lineHeight: '26px',
                  letterSpacing: '0.46px',
                  textAlign: 'left',
                }}
              >
                ENTRAR COM
              </Typography>
              <img src={IconMtLoginBlue} alt='ENTRAR COM MT LOGIN' style={{ paddingLeft: '15px' }} />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
                <Avatar {...stringAvatar(name ? name : '')} variant='square' />
                <Box
                  sx={{
                    ml: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography
                    variant='body2'
                    sx={{
                      color: 'text.primary',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{
                      color: 'text.primary',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                    }}
                  >
                    {cpf && formatCpfHiddenNumberMask(cpf)}
                  </Typography>
                </Box>
              </Box>
              <img src={IconMtLoginGray} alt='ENTRAR COM MT LOGIN' style={{ paddingRight: '20px' }} />
            </Box>
          )}
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;
