import { User } from 'api/user/api.types';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { validateLoggedUser } from 'services/auth/authService';
import { decriptografar } from 'services/utils/encryption';

interface AuthContextData {
  authenticated: boolean;
  user: User | null;
  validateLogin(): Promise<User | null>;
  invalidRequest: boolean;
  setInvalidRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [invalidRequest, setInvalidRequest] = useState<boolean>(false);

  async function validateLogin() {
    const result = await validateLoggedUser();

    if (result.user) {
      setUser(result.user);
      return result.user;
    } else {
      setUser(null);
      if (result.invalidRequest) {
        setInvalidRequest(true);
      }
      return null;
    }
  }

  useEffect(() => {
    const userJSONCripto = localStorage.getItem('user_data');
    if (userJSONCripto) {
      const userData = JSON.parse(decriptografar(userJSONCripto));
      setUser({ ...userData });
    }
  }, []);
  return <AuthContext.Provider value={{ authenticated: false, user, validateLogin, invalidRequest, setInvalidRequest }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
