import { CONFIG, getURLMTLogout } from './config';

interface responseGetUserInfoMtLogin {
  userInfo: { name: string; email: string; cpf: string } | null;
  error?: { error: string; error_description: string };
}

interface responseGetTokenMtLogin {
  accessToken: string | null;
  error?: { error: string; error_description: string };
}

export const getUserInfoMtLogin = async (accessToken: string): Promise<responseGetUserInfoMtLogin> => {
  try {
    const resp = await fetch(CONFIG.url_userInfo, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: new URLSearchParams({
        access_token: accessToken,
        client_id: CONFIG.client_id,
      }),
    });

    const respJson = await resp.json();

    if (!respJson.error || !respJson.error_description) {
      const { given_name, family_name, email, preferred_username } = respJson;

      return { userInfo: { name: given_name + ' ' + family_name, email, cpf: preferred_username } };
    } else {
      return { userInfo: null, error: respJson };
    }
  } catch (error) {
    console.log('getUserInfoMtLogin -> ERRO AO CONSULTAR OS DADOS DO USUÁRIO NO MT LOGIN', error);
    return {
      userInfo: null,
      error: {
        error: 'fetch_error',
        error_description:
          'Ocorreu um erro ao tentar consultar os dados do usuário no MT Login. Por favor, verifique sua conexão ou tente novamente mais tarde.',
      },
    };
  }
};

export const getTokenMtLogin = async (code: string): Promise<responseGetTokenMtLogin> => {
  try {
    const resp = await fetch(CONFIG.url_token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: CONFIG.grant_type,
        client_id: CONFIG.client_id,
        code: code,
        redirect_uri: CONFIG.redirect_uri,
      }),
    });

    const respJson = await resp.json();

    if (respJson && respJson.access_token) {
      return { accessToken: respJson.access_token };
    } else {
      return { accessToken: null, error: respJson };
    }
  } catch (error) {
    console.log('getTokenMtLogin -> ERRO AO OBTER O TOKEN DE ACESSO NO MT LOGIN', error);
    return {
      accessToken: null,
      error: {
        error: 'fetch_error',
        error_description: 'Ocorreu um erro ao tentar obter o token de acesso no MT Login. Por favor, verifique sua conexão ou tente novamente mais tarde.',
      },
    };
  }
};

export const getLogoutMtLogin = (): void => {
  localStorage.removeItem('user_data');
  window.location.href = getURLMTLogout();
};
