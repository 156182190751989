import React from 'react';
import NotFoundPage from '../pages/NotFoundPage';
import { ROUTES } from './constants';
import Home from '../pages/Home';
import { Login, LoginValidation } from '../pages/Login';
import { TermsOfUseAndConfidentiality } from 'pages/TermsOfUseAndConfidentiality';
import { ManageUser, MenuUser, UserView } from 'pages/User';
import UserForm from 'pages/User/UserForm';

export interface RouteConfig {
  path: string;
  element: React.FC;
  exact?: boolean;
}

const RoutesConfig: RouteConfig[] = [
  {
    path: '*',
    element: NotFoundPage,
  },
  {
    path: ROUTES.LOGIN,
    element: Login,
  },
  {
    path: ROUTES.LOGIN_VALIDATION,
    element: LoginValidation,
  },
  {
    path: ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY,
    element: TermsOfUseAndConfidentiality,
  },
  {
    path: ROUTES.HOME,
    element: Home,
  },
  {
    path: ROUTES.MENU_USER,
    element: MenuUser,
  },
  {
    path: ROUTES.MANAGE_USER,
    element: ManageUser,
  },
  {
    path: ROUTES.USER_CREATE,
    element: UserForm,
  },
  {
    path: ROUTES.USER_EDIT,
    element: UserForm,
  },
  {
    path: ROUTES.USER_VIEW,
    element: UserView,
  },
];

export default RoutesConfig;
