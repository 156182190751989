import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination, Typography } from '@mui/material';
import { TopNavBar } from 'components/TopNavBar';
import { Header } from 'components/Home';
import { MenuProvider } from 'contexts/menu/MenuContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FiltersDialog, UserCard } from 'components/User';
import { useQuery } from 'react-query';
import { UserListQuery, UserStatus } from 'api/user/api.types';
import { blockUsers, getListUsersByFilters } from 'api/user/user.api';
import { getGroupsDropdown } from 'api/group/group.api';
import { ListDropdown } from 'api/common.types';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { ErrorDialog, OrderDialog } from 'components/Shared';
import { getProfilesDropdown } from 'api/profile/profile.api';
import { getPermissionsDropdown } from 'api/permission/permission.api';
import CloseIcon from '@mui/icons-material/Close';
import LockRedIcon from '../../assets/icons/lock-red-icon.svg';

const ManageUser: React.FC = () => {
  const navigate = useNavigate();

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [blockingUserIds, setBlockingUserIds] = useState<string[]>([]);

  const [groupsDropdown, setGroupsDropdown] = useState<ListDropdown[]>([]);
  const [profilesDropdown, setProfilesDropdown] = useState<ListDropdown[]>([]);
  const [permissionsDropdown, setPermissionsDropdown] = useState<ListDropdown[]>([]);
  const [userStatusDropdown, setUserStatusDropdown] = useState<ListDropdown[]>([]);

  const [toggleOrderDialog, setToggleOrderDialog] = useState(false);
  const [toggleFiltersDialog, setToggleFiltersDialog] = useState(false);
  const [toggleConfirmUserBlockingDialog, setToggleConfirmUserBlockingDialog] = useState(false);

  const [selectedSort, setSelectedSort] = useState<string>('recent');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [invalidRequest, setInvalidRequest] = useState(false);

  const [filters, setFilters] = useState<UserListQuery>(() => {
    const savedFilters = localStorage.getItem('userFilters');
    return savedFilters ? JSON.parse(savedFilters) : { page: '1', limit: '10', sort: 'recent' };
  });

  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedProfile, setSelectedProfile] = useState('');
  const [selectedPermission, setSelectedPermission] = useState('');
  const [selectedUserStatus, setSelectedUserStatus] = useState('');

  useEffect(() => {
    const savedFilters = loadFiltersFromLocalStorage();
    if (savedFilters) {
      setFilters(savedFilters);
      setName(savedFilters.nome || '');
      setCpf(savedFilters.cpf || '');
      setSelectedGroup(savedFilters.groupUuid || '');
      setSelectedProfile(savedFilters.profileUuid || '');
      setSelectedPermission(savedFilters.permissionUuid || '');
      setSelectedUserStatus(savedFilters.status || '');
    }
  }, []);

  const hasAppliedFilters = () => {
    const { nome, cpf, groupUuid, profileUuid, permissionUuid, status } = filters;
    return !!(nome || cpf || groupUuid || profileUuid || permissionUuid || status);
  };

  useEffect(() => {
    const savedFilters = loadFiltersFromLocalStorage();
    if (savedFilters) {
      setFilters(savedFilters);
    }

    const statusOptions: ListDropdown[] = Object.entries(UserStatus).map(([key, value]) => ({
      uuid: key,
      name: value,
    }));
    setUserStatusDropdown(statusOptions);

    const fetchDropdowns = async () => {
      setGroupsDropdown(await getGroupsDropdown());
      setProfilesDropdown(await getProfilesDropdown());
      setPermissionsDropdown(await getPermissionsDropdown());
    };
    fetchDropdowns();
  }, []);

  const fetchUsers = async (): Promise<any> => {
    try {
      const usersData = await getListUsersByFilters(filters);
      setTotalPages(Math.ceil(usersData.total / parseInt(filters.limit, 10)));
      return usersData;
    } catch (error) {
      console.error('Error fetching users:', error);
      setInvalidRequest(true);
      return null;
    }
  };

  const { data: usersData, refetch: refetchUsers } = useQuery(['users', filters], fetchUsers, {
    enabled: groupsDropdown.length > 0,
    keepPreviousData: true,
    retry: 1,
  });

  useEffect(() => {
    if (usersData?.users) {
      const allSelected = usersData.users.every((user: any) => selectedUsers.includes(user.uuid));
      setSelectAllChecked(allSelected);
    } else {
      setSelectAllChecked(false);
    }
  }, [usersData, selectedUsers]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && usersData) {
      const allUserIds = usersData.users.map((user: any) => user.uuid);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleUserSelect = (userId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUsers((prevSelected) => (event.target.checked ? [...prevSelected, userId] : prevSelected.filter((id) => id !== userId)));
  };

  const handleToggleBlockingDialog = (userIds: string[]) => {
    const usersToBlock = usersData?.users.filter((user: any) => userIds.includes(user.uuid) && user.status !== 'bloqueado').map((user: any) => user.uuid);

    if (usersToBlock.length > 0) {
      setBlockingUserIds(usersToBlock);
      setToggleConfirmUserBlockingDialog(true);
    }
  };

  const showBlockButton = selectAllChecked || selectedUsers.length > 0;
  const allSelectedBlocked = selectedUsers.every((userId) => {
    const user = usersData?.users.find((u: any) => u.uuid === userId);
    return user?.status === 'bloqueado';
  });

  const handleCloseBlockingDialog = () => {
    setBlockingUserIds([]);
    setToggleConfirmUserBlockingDialog(false);
  };

  const handleBlockUsers = async () => {
    try {
      await blockUsers(blockingUserIds);
      handleCloseBlockingDialog();
      setSelectAllChecked(false);
      setSelectedUsers([]);
      await refetchUsers();
    } catch (error) {
      console.error('Error blocking users:', error);
      setInvalidRequest(true);
    }
  };

  const applySort = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sort: selectedSort,
    }));
    setToggleOrderDialog(false);
  };

  const applyFilters = async (name: string, cpf: string, selectedGroup: string, selectedProfile: string, selectedPermission: string, userStatus: string) => {
    try {
      const statusKey = Object.keys(UserStatus).find((key) => UserStatus[key as keyof typeof UserStatus] === userStatus);

      const newFilters: UserListQuery = {
        nome: name,
        cpf,
        groupUuid: selectedGroup,
        profileUuid: selectedProfile,
        permissionUuid: selectedPermission,
        status: UserStatus[statusKey as keyof typeof UserStatus],
        page: filters.page,
        limit: filters.limit,
        sort: filters.sort,
      };

      const response = await getListUsersByFilters(newFilters);

      if (response && response.total > 0) {
        setFilters(newFilters);

        saveFiltersToLocalStorage(newFilters);

        return true;
      }

      return false;
    } catch (error) {
      console.error('Error applying filters:', error);

      setInvalidRequest(true);

      return false;
    }
  };

  const handleToggleOrderDialog = () => {
    setToggleOrderDialog(!toggleOrderDialog);
  };

  const handleToggleFiltersDialog = () => {
    setToggleFiltersDialog(!toggleFiltersDialog);
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSort(event.target.value);
  };

  const handleClearFilters = () => {
    setFilters({
      page: '1',
      limit: '10',
      sort: 'recent',
    });
    localStorage.removeItem('userFilters');
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: String(page),
    }));
  };

  const handleCloseDialog = () => {
    setInvalidRequest(false);
  };

  const handleRefresh = () => {
    window.location.reload();
    setInvalidRequest(false);
  };

  const saveFiltersToLocalStorage = (filters: UserListQuery) => {
    localStorage.setItem('userFilters', JSON.stringify(filters));
  };

  const loadFiltersFromLocalStorage = (): UserListQuery | null => {
    const savedFilters = localStorage.getItem('userFilters');
    if (savedFilters) {
      return JSON.parse(savedFilters);
    }
    return null;
  };

  return (
    <MenuProvider>
      <>
        <TopNavBar />
        <Box sx={{ backgroundColor: '#0048B6', marginTop: '10px', height: '65px', alignContent: 'center' }}>
          <Header title='Gerenciar Usuários' showBackButton={true} />
          <Box sx={{ backgroundColor: '#0048B6', height: '190px', display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
            <Box sx={{ width: '1552px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Chip
                    label='Ordernar'
                    onClick={handleToggleOrderDialog}
                    onDelete={handleToggleOrderDialog}
                    deleteIcon={<ArrowDropDownIcon style={{ color: '#FFFFFF' }} />}
                    variant='outlined'
                    sx={{ color: '#FFFFFF', border: '1px solid #FFFFFF', marginRight: '10px' }}
                  />
                  <Chip
                    label='Filtrar'
                    onClick={handleToggleFiltersDialog}
                    onDelete={handleToggleFiltersDialog}
                    deleteIcon={<ArrowDropDownIcon style={{ color: hasAppliedFilters() ? '#0048B6' : '#FFFFFF' }} />}
                    variant='outlined'
                    sx={{
                      color: hasAppliedFilters() ? '#0048B6' : '#FFFFFF',
                      backgroundColor: hasAppliedFilters() ? '#FFFFFF' : '#0048B6',
                      border: '1px solid #FFFFFF',
                    }}
                  />
                  {hasAppliedFilters() && (
                    <Button variant='text' startIcon={<CloseIcon />} sx={{ color: '#FFFFFF', marginLeft: '10px' }} onClick={handleClearFilters}>
                      Limpar filtros
                    </Button>
                  )}
                </Box>
                <Button
                  variant='outlined'
                  startIcon={<AddIcon />}
                  sx={{ color: '#FFFFFF', border: '1px solid #FFFFFF' }}
                  onClick={() => navigate(ROUTES.USER_CREATE)}
                >
                  CADASTRAR USUÁRIO
                </Button>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
                <Checkbox sx={{ color: '#FFFFFF' }} checked={selectAllChecked} onChange={handleSelectAll} />
                <Typography sx={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '27px' }}>
                  Selecionar todos | {selectedUsers.length} de {usersData?.total || 0} selecionado(s)
                </Typography>
                {showBlockButton && !allSelectedBlocked && (
                  <Button
                    variant='outlined'
                    startIcon={<LockOutlinedIcon />}
                    onClick={() => handleToggleBlockingDialog(selectedUsers)}
                    sx={{ color: '#FFFFFF', border: '1px solid #FFFFFF', marginLeft: '15px' }}
                  >
                    BLOQUEAR SELECIONADO(S)
                  </Button>
                )}
              </Box>
              {usersData?.users?.map((user: any) => {
                const userGroup = groupsDropdown.find((group) => group.uuid === user.groupUuid);
                return (
                  <UserCard
                    key={user.uuid}
                    userUuid={user.uuid}
                    name={user.name}
                    cpf={user.cpf}
                    city={user.city}
                    group={userGroup ? userGroup.name : ''}
                    profile='Secretaria de Estado de Agricultura Familiar'
                    permission='Administrador'
                    status={user.status}
                    isChecked={selectedUsers.includes(user.uuid)}
                    onCheckboxChange={handleUserSelect(user.uuid)}
                    onBlockUser={() => handleToggleBlockingDialog([user.uuid])}
                    blockDisabled={user.status === 'bloqueado'}
                  />
                );
              })}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <Pagination count={totalPages} page={currentPage} color='primary' onChange={handlePageChange} />
              </Box>
            </Box>
          </Box>
        </Box>

        <OrderDialog
          open={toggleOrderDialog}
          selectedSort={selectedSort}
          onClose={() => setToggleOrderDialog(false)}
          handleSortChange={handleSortChange}
          applySort={applySort}
        />

        <FiltersDialog
          open={toggleFiltersDialog}
          onClose={() => setToggleFiltersDialog(false)}
          applyFilters={applyFilters}
          groupsDropdown={groupsDropdown}
          profilesDropdown={profilesDropdown}
          permissionsDropdown={permissionsDropdown}
          userStatusDropdown={userStatusDropdown}
          initialName={name}
          initialCpf={cpf}
          initialGroup={selectedGroup}
          initialProfile={selectedProfile}
          initialPermission={selectedPermission}
          initialUserStatus={selectedUserStatus}
        />

        <Dialog open={toggleConfirmUserBlockingDialog} onClose={handleCloseBlockingDialog} fullWidth maxWidth='md'>
          <DialogTitle sx={{ textAlign: 'center' }}>
            <img src={LockRedIcon} alt='Lock Icon' />
            <Typography sx={{ fontSize: '24px', marginTop: '20px' }}>Bloquear Usuário(s)</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: 'center' }}>Tem certeza que você deseja bloquear o(s) Usuário(s)?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', marginBottom: '30px' }}>
            <Button variant='contained' onClick={handleBlockUsers} sx={{ backgroundColor: '#D32F2F', '&:hover': { backgroundColor: '#D32F2F' } }}>
              CONFIRMAR BLOQUEIO
            </Button>
            <Button onClick={handleCloseBlockingDialog}>VOLTAR</Button>
          </DialogActions>
        </Dialog>

        <ErrorDialog
          open={invalidRequest}
          title='Ocorreu um erro na sua solicitação'
          message1='Deseja atualizar a página atual?'
          button1Text='FECHAR'
          button1Action={handleCloseDialog}
          button1Color='inherit'
          button2Text='ATUALIZAR'
          button2Action={handleRefresh}
          button2Color='primary'
        />
      </>
    </MenuProvider>
  );
};

export default ManageUser;
