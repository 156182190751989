import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Typography, Accordion, AccordionSummary, AccordionDetails, Box, IconButton, Divider } from '@mui/material';
import { CustomLabel } from 'components/Shared';

const labelStyle = {
  color: '#00000099',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  textAlign: 'left',
};

const valueStyle = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textAlign: 'left',
};

interface RegistrationDataDisplayProps {
  values: {
    activeRegistration: string;
    blockRegistration: string;
  };
  onEditClick?: () => void;
  isView: boolean;
}

const RegistrationDataDisplay: React.FC<RegistrationDataDisplayProps> = ({ values, onEditClick, isView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = (event: React.MouseEvent) => {
    if (onEditClick) {
      event.stopPropagation();
      onEditClick();
    }
  };

  return (
    <Box sx={{ width: '1152px', marginTop: '20px' }}>
      <Accordion expanded={expanded} onChange={handleAccordionToggle}>
        <AccordionSummary expandIcon={null} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <IconButton
                onClick={handleAccordionToggle}
                sx={{
                  marginRight: '10px',
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <ExpandMoreIcon />
              </IconButton>

              <Typography variant='h6'>Dados do cadastro</Typography>
              {!isView && (
                <Button
                  startIcon={<EditOutlinedIcon />}
                  variant='text'
                  sx={{ marginLeft: 'auto', textTransform: 'none', color: '#0048B6' }}
                  onClick={handleEditClick}
                >
                  Editar
                </Button>
              )}
            </Box>
            {expanded && (
              <Box sx={{ width: '100%' }}>
                <Divider sx={{ border: '1px solid #0000001F' }} />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            {/* Bloco de Cadastro Ativo e Bloquear Cadastro */}
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Cadastro Ativo' labelStyle={labelStyle} />
                <CustomLabel text={values.activeRegistration} labelStyle={valueStyle} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Bloquear Cadastro' labelStyle={labelStyle} />
                <CustomLabel text={values.blockRegistration} labelStyle={valueStyle} />
              </Box>
            </Box>
            {/* Bloco de Vigência - Data Inicial do Usuário e Vigência - Data Final: */}
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Vigência - Data Inicial:' labelStyle={labelStyle} />
                <CustomLabel text='N/A' labelStyle={valueStyle} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Vigência - Data Final:' labelStyle={labelStyle} />
                <CustomLabel text='N/A' labelStyle={valueStyle} />
              </Box>
            </Box>
            {/* Bloco de Data Final Indeterminada: e Status da Vigência: */}
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Data Final Indeterminada:' labelStyle={labelStyle} />
                <CustomLabel text='N/A' labelStyle={valueStyle} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Status da Vigência:' labelStyle={labelStyle} />
                <CustomLabel text='N/A' labelStyle={valueStyle} />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default RegistrationDataDisplay;
