import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/permissions';

export const getPermissionsDropdown = async (profileUuid?: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/dropdown`, {
    params: profileUuid ? { profileUuid } : {},
  });

  return response.data;
};

export const getPermission = async (permissionUuid: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/uuid/${permissionUuid}`);

  return response.data;
};
