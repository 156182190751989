import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomAlert, CustomRadioGroup, CustomSelect, CustomTextField } from 'components/Shared';
import HistoryIcon from '@mui/icons-material/History';
import { UserFormAction } from 'pages/User/UserForm';
import closeFilledIcon from '../../assets/icons/close_filled.svg';
import { Field } from 'formik';

interface RegistrationDataFormProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  userFormAction: UserFormAction;
  permissionMandatoryValidityValidation: boolean;
}

const RegistrationDataForm: React.FC<RegistrationDataFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  userFormAction,
  permissionMandatoryValidityValidation,
}) => {
  const [isValidityDialogOpen, setIsValidityDialogOpen] = useState(false);
  const [isManagementTermDialogOpen, setIsManagementTermDialogOpen] = useState(false);

  const getErrorMessage = () => {
    if (errors.registrationData?.activeRegistration && touched.registrationData?.activeRegistration) {
      return 'Selecione a opção que deseja de "Cadastro Ativo" abaixo para prosseguir.';
    }
    if (errors.registrationData?.blockRegistration && touched.registrationData?.blockRegistration) {
      return 'Selecione a opção que deseja de "Bloquear Cadastro" abaixo para prosseguir.';
    }
    return null;
  };
  const errorMessage = getErrorMessage();

  return (
    <>
      <Box sx={{ width: '1152px', marginTop: '20px' }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h6'>Dados do cadastro</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {errorMessage && <CustomAlert severity='error' message={errorMessage} />}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                <FormControl>
                  <FormLabel
                    id='activeRegistration'
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '22.88px',
                      letterSpacing: '0.17px',
                      textAlign: 'left',
                      color: '#00000099',
                      '&.Mui-focused': {
                        color: '#00000099',
                      },
                    }}
                  >
                    Cadastro Ativo:
                  </FormLabel>
                  <CustomRadioGroup
                    aria-labelledby='activeRegistration'
                    name='registrationData.activeRegistration'
                    value={values.registrationData.activeRegistration}
                    onChange={handleChange}
                  >
                    <FormControlLabel value={true} control={<Radio />} label='Sim' />
                    <FormControlLabel value={false} control={<Radio />} label='Não' />
                  </CustomRadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                <FormControl>
                  <FormLabel
                    id='blockRegistration'
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '22.88px',
                      letterSpacing: '0.17px',
                      textAlign: 'left',
                      color: '#00000099',
                      '&.Mui-focused': {
                        color: '#00000099',
                      },
                    }}
                  >
                    Bloquear Cadastro:
                  </FormLabel>
                  <CustomRadioGroup
                    aria-labelledby='blockRegistration'
                    name='registrationData.blockRegistration'
                    value={values.registrationData.blockRegistration}
                    onChange={handleChange}
                  >
                    <FormControlLabel value={true} control={<Radio />} label='Sim' />
                    <FormControlLabel value={false} control={<Radio />} label='Não' />
                  </CustomRadioGroup>
                </FormControl>
              </Box>
              {permissionMandatoryValidityValidation && (
                <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%', paddingRight: '8px' }}>
                  <FormControl>
                    <FormLabel
                      id='manageTerm'
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '22.88px',
                        letterSpacing: '0.17px',
                        textAlign: 'left',
                        color: '#00000099',
                        '&.Mui-focused': {
                          color: '#00000099',
                        },
                      }}
                    >
                      Gerenciar Vigência:
                    </FormLabel>
                    <Button
                      variant='outlined'
                      startIcon={<HistoryIcon />}
                      disabled={userFormAction === UserFormAction.EDITAR}
                      onClick={() => setIsValidityDialogOpen(!isValidityDialogOpen)}
                      sx={{
                        border: '1px solid #0048B680',
                        borderRadius: '4px',
                        marginTop: '10px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '24px',
                          letterSpacing: '0.4px',
                          textAlign: 'left',
                        }}
                      >
                        GERENCIAR VIGÊNCIA
                      </Typography>
                    </Button>
                  </FormControl>
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Dialog open={isValidityDialogOpen} fullWidth maxWidth='lg' sx={{ width: '100%' }}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              color: '#000000DE',
              marginTop: '20px',
            }}
          >
            Vigências Cadastradas
          </Typography>
          <Box
            component='button'
            onClick={() => setIsValidityDialogOpen(false)}
            sx={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              padding: '8px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: '#F0F0F0',
              },
            }}
          >
            <img src={closeFilledIcon} alt='Close' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Alert severity={'warning'}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20.02px',
                letterSpacing: '0.17px',
                textAlign: 'left',
              }}
            >
              Não localizamos Vigência cadastrada para esse usuário. Clique no botão abaixo ”Adicionar nova Vigência” para informar uma nova Vigência válida
              para o usuário.
            </Typography>
          </Alert>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button variant='contained' sx={{ backgroundColor: '#0048B6' }}>
              {/* onClick={() => setIsManagementTermDialogOpen(true)} */}
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                  color: '#FFFFFF',
                }}
              >
                ADICIONAR NOVA VIGÊNCIA
              </Typography>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}></DialogActions>
      </Dialog>

      <Dialog open={isManagementTermDialogOpen} fullWidth maxWidth='lg' sx={{ width: '100%' }}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              color: '#000000DE',
              marginTop: '20px',
            }}
          >
            Adicionar Nova Vigência de Gestão
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Box sx={{ flexBasis: '50%' }}>
                <Field
                  name='managementTerm.id'
                  as={CustomTextField}
                  label='ID'
                  variant='outlined'
                  size='small'
                  // disabled
                  // value={values.managementTerm.id}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginTop: '10px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      legend: {
                        width: '20px',
                      },
                    },
                  }}
                />
              </Box>

              <Box sx={{ flexBasis: '50%' }}>
                <FormControl size='small' fullWidth>
                  <InputLabel
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      fontWeight: 400,
                      letterSpacing: '0.15px',
                      textAlign: 'left',
                      color: '#00000099',
                      '&.Mui-focused': {
                        color: '#00000099',
                      },
                      marginTop: '10px',
                    }}
                  >
                    Status
                  </InputLabel>
                  <Field
                    name='managementTerm.status'
                    as={CustomSelect}
                    labelId='status-label'
                    id='status-select'
                    label='Status'
                    size='small'
                    // value={values.managementTerm.status}
                    onChange={handleChange}
                    fullWidth
                    sx={{
                      marginTop: '10px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        legend: {
                          width: '40px',
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value='active'
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                      }}
                    >
                      Ativo
                    </MenuItem>
                    <MenuItem
                      value='inactive'
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                      }}
                    >
                      Inativo
                    </MenuItem>
                  </Field>

                  {touched.managementTerm?.status && errors.managementTerm?.status && (
                    <Typography color='error' variant='caption' textAlign={'left'}>
                      {errors.managementTerm?.status}
                    </Typography>
                  )}
                </FormControl>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Box sx={{ flexBasis: '50%' }}>
                <Field
                  name='managementTerm.startDate'
                  as={CustomTextField}
                  label='Data inicial - de'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  size='small'
                  // value={values.managementTerm.startDate}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>

              <Box sx={{ flexBasis: '50%' }}>
                <Field
                  name='managementTerm.endDate'
                  as={CustomTextField}
                  label='Data Final'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  size='small'
                  // disabled={values.managementTerm.endDateIndefinite}
                  // value={values.managementTerm.endDate}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Field
                    name='managementTerm.endDateIndefinite'
                    type='checkbox'
                    as={Checkbox}
                    // checked={values.managementTerm.endDateIndefinite}
                    onChange={handleChange}
                  />
                }
                label='Data Final Indeterminada'
              />
            </Box>
            {/* ///// */}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}></DialogActions>
      </Dialog>
    </>
  );
};

export default RegistrationDataForm;
