/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Loading } from '../../components/Login';
import { AuthContext } from 'contexts/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ErrorDialog } from 'components/Shared';
import { ROUTES } from 'routes/constants';
import { UserStatus } from 'api/user/api.types';
import { getLogoutMtLogin } from 'services/auth/apiService';

const LoginValidation: React.FC = () => {
  const navigate = useNavigate();
  const { validateLogin, user, invalidRequest, setInvalidRequest } = useContext(AuthContext);
  const [userNotExistsError, setUserNotExistsError] = useState(false);

  const handleValidateLogin = async () => {
    try {
      const user = await validateLogin();
      if (user) {
        console.log(user);
        if (user.status === UserStatus.ATIVO) {
          if (!user.hasAcceptedTerms) {
            navigate(ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY);
          } else {
            navigate(ROUTES.HOME);
          }
        }
      } else {
        setUserNotExistsError(true);
      }
    } catch (error) {
      console.error('Erro ao validar o usuário:', error);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
    setInvalidRequest(false);
  };

  const handleCloseDialog = async () => {
    setInvalidRequest(false);
    getLogoutMtLogin();
  };

  useEffect(() => {
    console.log('useEffect -> LoginValidation');

    if (user) {
      console.log(user);
    } else {
      const validate = async () => {
        await handleValidateLogin();
      };
      validate();
    }
  }, [user]);

  return (
    <>
      <Loading />
      {user?.status === UserStatus.BLOQUEADO && (
        <ErrorDialog
          open={user?.status === UserStatus.BLOQUEADO}
          title='Usuário Bloqueado'
          message1='O acesso do Usuário informado encontra-se bloqueado.'
          message2='Orientamos que entre em contato com a Administração do sistema para realizar o desbloqueio do Usuário, ou Informe outro Usuário para realizar o acesso na página anterior.'
          button1Text='Voltar'
          button1Action={handleCloseDialog}
          button1Color='primary'
        />
      )}
      {user?.status === UserStatus.DESATIVADO && (
        <ErrorDialog
          open={user?.status === UserStatus.DESATIVADO}
          title='Usuário Inativo'
          message1='O acesso do Usuário informado não está ativo.'
          message2='Orientamos que entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário, ou Informe outro Usuário para realizar o acesso na página anterior.'
          button1Text='Voltar'
          button1Action={handleCloseDialog}
          button1Color='primary'
        />
      )}
      <ErrorDialog
        open={userNotExistsError}
        title='Usuário Não Encontrado'
        message1='O acesso do Usuário informado não foi localizado.'
        message2='Orientamos que entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário, ou Informe outro Usuário para realizar o acesso na página anterior.'
        button1Text='VOLTAR'
        button1Action={handleCloseDialog}
        button1Color='primary'
      />
      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={handleCloseDialog}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </>
  );
};

export default LoginValidation;
