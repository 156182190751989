import { getUserByCpf } from 'api/user/user.api';
import { getTokenMtLogin, getUserInfoMtLogin } from './apiService';
import { User, UserStatus } from 'api/user/api.types';
import { criptografar, decriptografar } from 'services/utils/encryption';

interface responseValidateLoggedUser {
  user: User | null;
  invalidRequest?: boolean;
}

interface UserDataStorage {
  accessTokenMtLogin: string;
  uuid: string;
  name: string;
  cpf: string;
  email: string;
  hasAcceptedTerms: boolean;
  status: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
}

export const validateLoggedUser = async (): Promise<responseValidateLoggedUser> => {
  try {
    const userDataStorage = getUserLocalStorage();
    let accessToken = '';
    if (userDataStorage?.accessTokenMtLogin) {
      accessToken = userDataStorage.accessTokenMtLogin;
    } else {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(String(prop)),
      });

      const code = (params as any).code;

      if (code) {
        const token = await getTokenMtLogin(code);
        if (token.accessToken) {
          accessToken = token.accessToken;
        } else {
          console.log('getTokenMtLogin -> ERRO AO OBTER O TOKEN DE ACESSO NO MT LOGIN', token.error);
          return { user: null, invalidRequest: true };
        }
      } else {
        console.log('Erro: nenhum code encontrado');
        return { user: null, invalidRequest: true };
      }
    }
    const userInfo = await getUserInfoMtLogin(accessToken);
    if (userInfo.userInfo) {
      console.log(accessToken);
      console.log(userInfo.userInfo);
      try {
        const user = await getUserByCpf(userInfo.userInfo.cpf);
        console.log(user);
        const userData = {
          uuid: '',
          name: userInfo.userInfo.name,
          cpf: userInfo.userInfo.cpf,
          email: userInfo.userInfo.email,
          hasAcceptedTerms: false,
          status: UserStatus.BLOQUEADO,
          createdAt: null,
          updatedAt: null,
          deletedAt: null,
        };
        if (user) {
          userData.uuid = user.uuid;
          userData.hasAcceptedTerms = user.hasAcceptedTerms;
          userData.status = UserStatus[user.status.toUpperCase() as keyof typeof UserStatus];
        }
        setUserLocalStorage(userData, accessToken);
        return {
          user: userData,
        };
      } catch (error: any) {
        if (error.response?.data?.statusCode === 404) {
          return { user: null };
        }
        console.log('getUserByCpf -> USUÁRIO NÃO ENCONTRADO', error.response?.data?.message);
        return { user: null, invalidRequest: true };
      }
    } else {
      console.log('getUserInfoMtLogin -> ERRO AO OBTER AS INFORMAÇÕES DO USUARIO', userInfo.error);
      return { user: null, invalidRequest: true };
    }
  } catch (error) {
    console.log('ERRO AO BUSCAR USUARIO LOGADO', error);
    return { user: null, invalidRequest: true };
  }
};

export const getUserLocalStorage = (): UserDataStorage | null => {
  const userJSONCripto = localStorage.getItem('user_data');

  if (userJSONCripto) {
    const userData = JSON.parse(decriptografar(userJSONCripto));
    return userData;
  } else {
    return null;
  }
};

export const setUserLocalStorage = (userData: User, accessTokenMtLogin: string): void => {
  const userDataStorage = { ...userData, accessTokenMtLogin };
  localStorage.setItem('user_data', criptografar(JSON.stringify(userDataStorage)));
};
