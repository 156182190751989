import React, { useEffect, useRef, useState } from 'react';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { TopNavBar } from 'components/TopNavBar';
import { Header } from 'components/Home';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import UserFormSteps from 'components/User/UserFormSteps';
import PersonalDataForm from 'components/User/PersonalDataForm';
import LoginDataForm from 'components/User/LoginDataForm';
import { getMunicipalitiesDropdown } from 'api/municipalities/municipalities.api';
import { ListDropdown } from 'api/common.types';
import { getGroupsDropdown } from 'api/group/group.api';
import { getProfilesDropdown } from 'api/profile/profile.api';
import { getPermission, getPermissionsDropdown } from 'api/permission/permission.api';
import RegistrationDataForm from 'components/User/RegistrationDataForm';
import AttachFileForm from 'components/User/AttachFileForm';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validateCPF } from 'services/utils/validateCPF';
import { checkDuplicateUser, createUser, getUserDetails, partialUpdateUser } from 'api/user/user.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import PersonalDataDisplay from 'components/User/PersonalDataDisplay';
import LoginDataDisplay from 'components/User/LoginDataDisplay';
import RegistrationDataDisplay from 'components/User/RegistrationDataDisplay';
import RegisteredIcon from '../../assets/icons/registered-icon.svg';
import UpdatedIcon from '../../assets/icons/updated-icon.svg';
import { DynamicDialog, ErrorDialog } from 'components/Shared';
import { PartialUpdateUserData } from 'api/user/api.types';

export enum UserFormAction {
  CADASTRAR = 'CADASTRAR',
  EDITAR = 'EDITAR',
}

interface UserDetailsData {
  personalData: {
    uuid: string;
    name: string;
    cpf: string;
    email: string;
    phone: string;
    position: string;
    occupation: string;
    municipalityUuid: string;
  };
  loginData: {
    groupUuid: string;
    profileUuid: string;
    permissionUuid: string;
    password: string;
    confirmPassword: string;
  };
  registrationData: {
    activeRegistration: boolean;
    blockRegistration: boolean;
  };
}

const UserForm: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userFormAction, setUserFormAction] = useState<UserFormAction | null>(null);
  const [activeStep, setActiveStep] = useState(1);
  const [municipalitiesDropdown, setMunicipalitiesDropdown] = useState<ListDropdown[]>([]);
  const [groupsDropdown, setGroupsDropdown] = useState<ListDropdown[]>([]);
  const [profilesDropdown, setProfilesDropdown] = useState<ListDropdown[]>([]);
  const [permissionsDropdown, setPermissionsDropdown] = useState<ListDropdown[]>([]);
  const [userDetails, setUserDetails] = useState<UserDetailsData | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [duplicateError, setDuplicateError] = useState<string | null>(null);
  const [permissionMandatoryValidityValidation, setPermissionMandatoryValidityValidation] = useState(false);

  const cpfRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const handleGroupChange = async (groupUuid: string) => {
    if (!groupUuid) {
      setProfilesDropdown([]);
      // setIsProfileDisabled(true);
      return;
    }

    try {
      const profiles = await getProfilesDropdown(groupUuid);
      setProfilesDropdown(profiles);
      //setIsProfileDisabled(false); // Habilita o campo de perfis
      setPermissionsDropdown([]); // Limpa as permissões ao mudar o grupo
      //setIsPermissionDisabled(true); // Desabilita o campo de permissões
    } catch (error) {
      console.error('Erro ao buscar perfis:', error);
    }
  };

  const handleProfileChange = async (profileUuid: string) => {
    if (!profileUuid) {
      setPermissionsDropdown([]);
      // setIsPermissionDisabled(true);
      return;
    }

    try {
      const permissions = await getPermissionsDropdown(profileUuid);
      setPermissionsDropdown(permissions);
      // setIsPermissionDisabled(false); // Habilita o campo de permissões
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
    }
  };

  const handlePermissionChange = async (permissionUuid: string) => {
    if (permissionUuid) {
      try {
        const permissionData = await getPermission(permissionUuid);
        const permissionMandatoryValidityValidation = permissionData.mandatoryValidityValidation;
        setPermissionMandatoryValidityValidation(permissionData.mandatoryValidityValidation);

        console.log(permissionMandatoryValidityValidation);
        // setIsPermissionDisabled(false); // Habilita o campo de permissões
      } catch (error) {
        console.error('Erro ao buscar permissões:', error);
      }
    }
  };

  useEffect(() => {
    const fetchDropdowns = async () => {
      setMunicipalitiesDropdown(await getMunicipalitiesDropdown());
      setGroupsDropdown(await getGroupsDropdown());
      //setProfilesDropdown(await getProfilesDropdown());
      //setPermissionsDropdown(await getPermissionsDropdown());
    };

    const fetchUserDetails = async (uuid: string) => {
      try {
        const userDetailsData = await getUserDetails(uuid);
        if (userDetailsData) {
          setUserDetails({
            personalData: {
              uuid: userDetailsData.uuid,
              name: userDetailsData.name,
              cpf: userDetailsData.cpf,
              email: userDetailsData.email,
              phone: userDetailsData.phone,
              position: userDetailsData.position,
              occupation: userDetailsData.occupation,
              municipalityUuid: userDetailsData.municipalityUuid,
            },
            loginData: {
              groupUuid: userDetailsData.groupUuid,
              profileUuid: userDetailsData.profileUuid,
              permissionUuid: userDetailsData.permissionUuid,
              password: '123123',
              confirmPassword: '123123',
            },
            registrationData: {
              activeRegistration: userDetailsData.activeRegistration,
              blockRegistration: userDetailsData.blockRegistration,
            },
          });
        } else {
          setInvalidRequest(true);
        }
      } catch (error) {
        setInvalidRequest(true);
      }
    };

    fetchDropdowns();

    const pathParts = location.pathname.split('/');
    const action = pathParts[2];
    const userUuid = pathParts[3];

    if (action === 'cadastrar') {
      setUserFormAction(UserFormAction.CADASTRAR);
    } else if (action === 'editar' && userUuid) {
      setUserFormAction(UserFormAction.EDITAR);
      fetchUserDetails(userUuid);
    }
  }, [location.pathname]);

  const initialValues = {
    personalData: {
      name: userDetails?.personalData?.name || '',
      cpf: userDetails?.personalData?.cpf || '',
      email: userDetails?.personalData?.email || '',
      phone: userDetails?.personalData?.phone || '',
      position: userDetails?.personalData?.position || '',
      occupation: userDetails?.personalData?.occupation || '',
      municipality: userDetails?.personalData?.municipalityUuid || '',
    },
    loginData: {
      group: userDetails?.loginData?.groupUuid || '',
      profile: userDetails?.loginData?.profileUuid || '',
      permission: userDetails?.loginData?.permissionUuid || '',
      password: userDetails?.loginData?.password || '',
      confirmPassword: userDetails?.loginData?.confirmPassword || '',
    },
    registrationData: {
      activeRegistration: userDetails?.registrationData?.activeRegistration === true,
      blockRegistration: userDetails?.registrationData?.blockRegistration === true,
    },
  };

  const validationSchema = Yup.object().shape({
    personalData: Yup.object().shape({
      name: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
      cpf: Yup.string()
        .required('Preencha esse campo corretamente, com um dado válido.')
        .test('cpf-valid', 'CPF inválido', (value) => validateCPF(value || '')),
      email: Yup.string().email('Preencha esse campo corretamente, com um dado válido.').required('Preencha esse campo corretamente, com um dado válido.'),
      phone: Yup.string()
        .required('Preencha esse campo corretamente, com um dado válido.')
        .test('valid-phone', 'Número de telefone inválido', (value) => {
          if (!value) return false;
          const cleaned = value.replace(/\D/g, '');
          return cleaned.length === 10 || cleaned.length === 11;
        }),
      position: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
      occupation: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
      municipality: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
    }),
    loginData: Yup.object().shape({
      group: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
      profile: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
      permission: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
      password: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
      confirmPassword: Yup.string()
        .required('Preencha esse campo corretamente, com um dado válido.')
        .oneOf([Yup.ref('password')], 'As senhas devem coincidir'),
    }),
    registrationData: Yup.object().shape({
      activeRegistration: Yup.boolean().required('Selecione se o cadastro está ativo.'),
      blockRegistration: Yup.boolean().required('Selecione se deseja bloquear o cadastro.'),
    }),
  });

  const handleEditClick = () => {
    setActiveStep(1);
  };

  const removeCpfMask = (cpf: string) => cpf.replace(/\D/g, '');

  const validateCpfAndEmail = async (cpf: string, email: string): Promise<boolean> => {
    if (cpf && email) {
      try {
        const { cpfExists, emailExists } = await checkDuplicateUser(removeCpfMask(cpf), email);

        if (cpfExists && emailExists) {
          setDuplicateError('CPF e E-mail já estão cadastrados.');
          cpfRef.current?.focus();
          return true;
        } else if (cpfExists) {
          setDuplicateError('CPF já está cadastrado.');
          cpfRef.current?.focus();
          return true;
        } else if (emailExists) {
          setDuplicateError('E-mail já está cadastrado.');
          emailRef.current?.focus();
          return true;
        } else {
          setDuplicateError(null);
          return false;
        }
      } catch (error) {
        console.error('Erro ao verificar duplicidade:', error);
        setDuplicateError('Erro ao verificar duplicidade. Tente novamente.');
        return true;
      }
    }

    return false;
  };

  const clearDuplicateError = () => {
    setDuplicateError(null);
  };

  const handleSubmit = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    if (activeStep === 1 && userFormAction === UserFormAction.CADASTRAR) {
      const { cpf, email } = values.personalData;
      const userIsDuplicate = await validateCpfAndEmail(cpf, email);

      if (!userIsDuplicate) setActiveStep(2);
    } else if (activeStep === 1 && userFormAction === UserFormAction.EDITAR) {
      setActiveStep(2);
    } else if (activeStep === 2) {
      const userData = {
        name: values.personalData.name,
        cpf: values.personalData.cpf,
        email: values.personalData.email,
        phone: values.personalData.phone,
        position: values.personalData.position,
        occupation: values.personalData.occupation,
        municipalityUuid: values.personalData.municipality,
        groupUuid: values.loginData.group,
        profileUuid: values.loginData.profile,
        permissionUuid: values.loginData.permission,
        password: values.loginData.password,
        activeRegistration: values.registrationData.activeRegistration,
        blockRegistration: values.registrationData.blockRegistration,
      };

      try {
        if (userFormAction === UserFormAction.CADASTRAR) {
          const newUser = await createUser(userData);

          if (newUser) {
            setIsDialogOpen(true);
          }
        } else if (userFormAction === UserFormAction.EDITAR && userDetails?.personalData.uuid) {
          const hasChanged = (original: any, updated: any) => {
            return JSON.stringify(original) !== JSON.stringify(updated);
          };

          const partialUpdateUserData: PartialUpdateUserData = {};

          // if (hasChanged(userDetails?.personalData.name, values.personalData.name)) {
          //   partialUpdateUserData.name = values.personalData.name;
          // }
          // if (hasChanged(userDetails?.personalData.cpf, values.personalData.cpf)) {
          //   partialUpdateUserData.cpf = values.personalData.cpf;
          // }
          if (hasChanged(userDetails?.personalData.email, values.personalData.email)) {
            partialUpdateUserData.email = values.personalData.email;
          }
          if (hasChanged(userDetails?.personalData.phone, values.personalData.phone)) {
            partialUpdateUserData.phone = values.personalData.phone;
          }
          if (hasChanged(userDetails?.personalData.position, values.personalData.position)) {
            partialUpdateUserData.position = values.personalData.position;
          }
          if (hasChanged(userDetails?.personalData.occupation, values.personalData.occupation)) {
            partialUpdateUserData.occupation = values.personalData.occupation;
          }
          // if (hasChanged(userDetails?.personalData.municipalityUuid, values.personalData.municipality)) {
          //   partialUpdateUserData.municipalityUuid = values.personalData.municipality;
          // }

          // if (hasChanged(userDetails?.loginData.groupUuid, values.loginData.group)) {
          //   partialUpdateUserData.groupUuid = values.loginData.group;
          // }
          // if (hasChanged(userDetails?.loginData.profileUuid, values.loginData.profile)) {
          //   partialUpdateUserData.profileUuid = values.loginData.profile;
          // }
          // if (hasChanged(userDetails?.loginData.permissionUuid, values.loginData.permission)) {
          //   partialUpdateUserData.permissionUuid = values.loginData.permission;
          // }

          if (hasChanged(userDetails?.registrationData.activeRegistration, values.registrationData.activeRegistration)) {
            partialUpdateUserData.activeRegistration = Boolean(values.registrationData.activeRegistration);
          }
          if (hasChanged(userDetails?.registrationData.blockRegistration, values.registrationData.blockRegistration)) {
            partialUpdateUserData.blockRegistration = Boolean(values.registrationData.blockRegistration);
          }

          if (Object.keys(partialUpdateUserData).length > 0) {
            await partialUpdateUser(userDetails?.personalData.uuid, partialUpdateUserData);
            setIsDialogOpen(true);
          } else {
            console.log('Nenhuma alteração detectada.');
          }
        }
      } catch (error) {
        console.error('Erro ao tentar criar ou alterar o usuário:', error);
      } finally {
        actions.setSubmitting(false);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setActiveStep(1);
    } else {
      navigate(ROUTES.MANAGE_USER);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
    setInvalidRequest(false);
  };

  const getNameByUuid = (uuid: string, list: { uuid: string; name: string }[]) => {
    const item = list.find((entry) => entry.uuid === uuid);
    return item ? item.name : 'N/A';
  };

  return (
    <MenuProvider>
      <TopNavBar />
      <Box
        sx={{
          backgroundColor: '#0048B6',
          marginTop: '10px',
          height: '65px',
          alignContent: 'center',
        }}
      >
        <Header title={userFormAction === UserFormAction.CADASTRAR ? 'Cadastrar usuário' : 'Editar usuário'} showBackButton={true} />
        <Box
          sx={{
            backgroundColor: '#FAFAFA',
          }}
        >
          <UserFormSteps activeStep={activeStep} />
          <Box
            sx={{
              height: '79px',
              gap: '24px',
            }}
          >
            <Typography variant='h5'>{activeStep === 1 ? 'Dados principais' : 'Confirmação dos dados'}</Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '22.88px',
                letterSpacing: '0.17px',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              {activeStep === 1
                ? 'Informe corretamente os dados principais do usuário cadastrado.'
                : 'Confira todas as informações que foram preenchidas, e altere-as caso necessário.'}
            </Typography>
          </Box>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty }) => {
              if (typeof values.registrationData.activeRegistration === 'string')
                values.registrationData.activeRegistration = JSON.parse(values.registrationData.activeRegistration);
              if (typeof values.registrationData.blockRegistration === 'string')
                values.registrationData.blockRegistration = JSON.parse(values.registrationData.blockRegistration);

              return (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>
                    {activeStep === 1 ? (
                      <>
                        <PersonalDataForm
                          municipalitiesOptions={municipalitiesDropdown}
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          duplicateError={duplicateError}
                          fieldsRef={{ cpfRef, emailRef }}
                          clearDuplicateError={clearDuplicateError}
                          userFormAction={userFormAction ? userFormAction : UserFormAction.CADASTRAR}
                        />
                        <LoginDataForm
                          groupOptions={groupsDropdown}
                          profileOptions={profilesDropdown}
                          permissionOptions={permissionsDropdown}
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={(e) => {
                            const { name, value } = e.target;

                            if (name === 'loginData.group') {
                              handleGroupChange(value);
                              values.loginData.profile = '';
                            }

                            if (name === 'loginData.profile') {
                              handleProfileChange(value);
                              values.loginData.permission = '';
                            }

                            if (name === 'loginData.permission') {
                              handlePermissionChange(value);
                            }

                            handleChange(e); // Continua propagando a mudança
                          }}
                          handleBlur={handleBlur}
                          userFormAction={userFormAction ? userFormAction : UserFormAction.CADASTRAR}
                        />
                        <RegistrationDataForm
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          userFormAction={userFormAction ? userFormAction : UserFormAction.CADASTRAR}
                          permissionMandatoryValidityValidation={permissionMandatoryValidityValidation}
                        />
                        <AttachFileForm userFormAction={userFormAction ? userFormAction : UserFormAction.CADASTRAR} />
                      </>
                    ) : (
                      <>
                        <PersonalDataDisplay
                          values={{
                            ...values.personalData,
                            municipality: getNameByUuid(values.personalData.municipality, municipalitiesDropdown),
                          }}
                          onEditClick={handleEditClick}
                          isView={false}
                        />
                        <LoginDataDisplay
                          values={{
                            group: getNameByUuid(values.loginData.group, groupsDropdown),
                            profile: getNameByUuid(values.loginData.profile, profilesDropdown),
                            permission: getNameByUuid(values.loginData.permission, permissionsDropdown),
                          }}
                          onEditClick={handleEditClick}
                          isView={false}
                        />
                        <RegistrationDataDisplay
                          values={{
                            activeRegistration: values.registrationData.activeRegistration ? 'Sim' : 'Não',
                            blockRegistration: values.registrationData.blockRegistration ? 'Sim' : 'Não',
                          }}
                          onEditClick={handleEditClick}
                          isView={false}
                        />
                      </>
                    )}
                  </Box>

                  <Box>
                    <Button
                      variant='text'
                      onClick={handleBack}
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.4px',
                        textAlign: 'left',
                        color: '#0048B6',
                        marginRight: '20px',
                      }}
                    >
                      VOLTAR
                    </Button>
                    <Button
                      variant='contained'
                      type='submit'
                      disabled={!isValid || !dirty}
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.4px',
                        textAlign: 'left',
                        background: !isValid || !dirty ? '#B0BEC5' : '#0048B6',
                      }}
                    >
                      {activeStep === 1 ? 'AVANÇAR' : 'CONFIRMAR E SALVAR'}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Box>

      <Box sx={{ width: '1200px' }}>
        <DynamicDialog
          isOpen={isDialogOpen}
          icon={userFormAction === UserFormAction.CADASTRAR ? RegisteredIcon : UpdatedIcon}
          title={userFormAction === UserFormAction.CADASTRAR ? 'Usuário Cadastrado' : 'Usuário Alterado'}
          message={userFormAction === UserFormAction.CADASTRAR ? 'O Usuário foi cadastrado com sucesso.' : 'O Usuário foi alterado com sucesso.'}
          buttonText='Concluir'
          onClose={() => setIsDialogOpen(false)}
          routeOnClose={ROUTES.MANAGE_USER}
        />
      </Box>

      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={() => setInvalidRequest(false)}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </MenuProvider>
  );
};

export default UserForm;
