import axios from 'axios';
import { PartialUpdateUserData, User, UserListQuery } from './api.types';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/users';

export const getUserDetails = async (uuid: string): Promise<any> => {
  const response = await axios.get<any>(`${API_URL}/details/${uuid}`);
  return response.data;
};

export const checkDuplicateUser = async (cpf?: string, email?: string): Promise<{ cpfExists: boolean; emailExists: boolean }> => {
  const params: { [key: string]: string | undefined } = {};

  if (cpf) params.cpf = cpf;
  if (email) params.email = email;

  const response = await axios.get(`${API_URL}/check-duplicate`, {
    params,
  });

  return response.data;
};

export const blockUsers = async (userUuids: string[]): Promise<string> => {
  const response = await axios.post<string>(`${API_URL}/block`, { userUuids });
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUser = async (userData: any): Promise<User | null> => {
  const response = await axios.post<User>(`${API_URL}`, userData);
  return response.data;
};

export const getUserByCpf = async (cpf: string): Promise<User | null> => {
  const response = await axios.get<any>(`${API_URL}/cpf/${cpf}`);
  return response.data;
};

export const partialUpdateUser = async (uuid: string, data: PartialUpdateUserData): Promise<User | null> => {
  const response = await axios.patch<User>(`${API_URL}/${uuid}`, data);
  return response.data;
};

export const getListUsersByFilters = async (filters: UserListQuery): Promise<any> => {
  const response = await axios.get(`${API_URL}/list`, {
    params: {
      nome: filters.nome,
      cpf: filters.cpf,
      groupUuid: filters.groupUuid,
      profileUuid: filters.profileUuid,
      permissionUuid: filters.permissionUuid,
      status: filters.status,
      page: filters.page || '1',
      limit: filters.limit || '10',
      sort: filters.sort || 'recent',
    },
  });

  return response.data;
};
