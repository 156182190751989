export interface User {
  uuid: string;
  name: string;
  cpf: string;
  email: string;
  hasAcceptedTerms: boolean;
  status: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
}

export interface PartialUpdateUserData {
  name?: string;
  cpf?: string;
  email?: string;
  phone?: string;
  position?: string;
  occupation?: string;
  activeRegistration?: boolean;
  blockRegistration?: boolean;
  hasAcceptedTerms?: boolean;
  status?: UserStatus;
}

export enum UserStatus {
  ATIVO = 'Ativo',
  BLOQUEADO = 'Bloqueado',
  DESATIVADO = 'Desativado',
}

export interface UserListQuery {
  nome?: string;
  cpf?: string;
  groupUuid?: string;
  profileUuid?: string;
  permissionUuid?: string;
  status?: UserStatus;
  page?: string;
  limit: string;
  sort?: string;
}
